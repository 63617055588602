import { FaRegMessage } from "react-icons/fa6";
import { MdClose, MdDeleteForever } from "react-icons/md";
import { onDeleteGovStaff } from "../../services/user";
import { toast } from "react-toastify";
import { useState } from "react";
import Spinner from "../generic/Spinner";
import ComposeMsg from "../../Pages/Userprotected/message/ComposeMsg";
import { useSelector } from "react-redux";
import Button from "../generic/Button";

export default ({ item }) => {
  const [loading, setLoading] = useState(false);
  var authuser = useSelector((state) => state.userStore);
  const [showdel, setshowdel] = useState(false);
  const [showsend, setshowsend] = useState(false);
  const DeleteStaff = async () => {
    setLoading(true);

    try {
      var res = await onDeleteGovStaff({ email: item.email });
      toast.success("Staff deleted!");
    } catch (e) {
      console.log(e);
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex gap-3 flex-col text-gray-700  ">
      {showsend && <SendMsgModal selecteditem={item} show={setshowsend} />}
      {showdel && (
        <ConfirmDeleteStaff
          show={setshowdel}
          action={async () => {
            await DeleteStaff({ email: item.email });
          }}
        />
      )}
      <Spinner loading={loading} />

      <p
        onClick={() => {
          setshowsend(true);
        }}
        className="hover:underline hover:text-blue-600"
      >
        <FaRegMessage className="inline" /> Send message
      </p>
      <p
        onClick={() => {
          setshowdel(true);
        }}
        className=" text-red hover:underline hover:text-blue-600"
      >
        <MdDeleteForever className="inline " /> Delete staff
      </p>
    </div>
  );
};

const SendMsgModal = ({ show, selecteditem }) => {
  var authuser = useSelector((state) => state.userStore);

  return (
    <div className=" w-screen h-screen fixed backdrop-blur-md top-0 left-0 bg-black/30 flex justify-center items-center z-[3000] overflow-y-scroll overflow-x-hidden">
      <div className="w-[50vw] bg-white relative mt-4 flex flex-col gap-2 p-4 justify-center items-center">
        <MdClose
          onClick={() => {
            show(false);
          }}
          size={20}
          className="absolute top-5 right-5"
        />
        <p className="">
          Send message to {selecteditem.firstname} {selecteditem.lastname}
        </p>

        <ComposeMsg
          show={show}
          message={{
            userto: `${selecteditem.firstname} ${selecteditem.lastname}`,
            to: selecteditem.email,
            from: authuser.email,
          }}
        />
      </div>
    </div>
  );
};

const ConfirmDeleteStaff = ({ show, action }) => (
  <div className=" w-screen h-screen fixed backdrop-blur-md top-0 left-0 bg-black/30 flex justify-center items-center z-[3000] overflow-y-scroll overflow-x-hidden">
    <div className="w-[50vw] bg-white relative mt-4 flex flex-col gap-2 p-4 justify-center items-center">
      <p className="text-lg font-[500] text-gray-800 ">
        Are you sure you want to delete this user
      </p>
      <p className="font-light text-gray-700 text-sm">
        This user will be deleted from the system
      </p>
      <div className="flex gap-4">
        <Button
          outlined
          width={200}
          text="Cancel"
          onBtnClick={() => {
            show(false);
          }}
        />

        <Button
        OverideStyle={"bg-rose-500"}
          width={200}
          text="Proceed"
          onBtnClick={async () => {
            await action();
          }}
        />
      </div>
    </div>
  </div>
);
