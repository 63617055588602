import { useState } from "react";
import { LGAs } from "../../constants/data";
import { useEffect } from "react";
import {
  PiArrowUpRight,
  PiArrowUpRightThin,
  PiEmptyLight,
} from "react-icons/pi";
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";
import { MdClose } from "react-icons/md";
import { ReviewStatusModal } from "../Review/ReviewStatusModal";
import { useDispatch } from "react-redux";
import StaffActions from "./StaffActions";

export default ({ list, asignedschhols, notasignedschhols,setshowmodal, allschools }) => {
  const [activeLGa, setactive] = useState(LGAs[0]);
  const [viewlist, setviewlist] = useState([]);
  const [stafflist, setstafflist] = useState([]);
  const [showlist, setshowlist] = useState(false);
  const [lgaschools, setlgaschools] = useState([]);
  const [evaluatedschools, setevaluatedschools] = useState([]);
  const [unevaluatedschools, setunevaluatedschools] = useState([]);
  const [listtilte, setlisttilte] = useState("");

  useEffect(() => {
    setevaluatedschools(
      asignedschhols.filter((kl) =>
        kl.address
          ?.toLowerCase()
          .includes(`${" "}${activeLGa.toLowerCase()}${" "}`)
      )
    );
  }, [asignedschhols, activeLGa]);

  useEffect(() => {
    setlgaschools(
      allschools .filter((it) =>
          it?.address
            ?.toLowerCase()
            .includes(`${" "}${activeLGa.toLowerCase()}${" osun state"}`)
        )
    );
  }, [allschools, activeLGa]);
  useEffect(() => {
    var newL = list.filter((tut) =>
      tut.lgas.toLowerCase().split(",").includes(activeLGa.toLowerCase())
    );
    setstafflist(newL);
    console.log(newL);
  }, [list, activeLGa]);

  const FilterItem = ({ item, click }) => (
    <div
      onClick={() => {
        setactive(item);
      }}
      className={`${
        activeLGa == item ? "bg-black/80 font-semibold  text-white" : "  "
      } cursor-pointer rounded  border border-gray-600  font-light min-w-[200px] text-sm p-2 flex justify-center items-center  mx-3`}
    >
      {" "}
      {item}
    </div>
  );

  useEffect(() => {
    var arr = lgaschools.filter((lk) =>
      evaluatedschools.some(
        (hh) => !(hh.schoolname.toLowerCase() == lk.schoolname.toLowerCase())
      )
    );
    if (evaluatedschools.length == 0) {
      setunevaluatedschools(lgaschools);
    } else {
      setunevaluatedschools(arr);
    }
  }, [lgaschools, evaluatedschools, activeLGa]);

  useEffect(() => {
    setviewlist([]);
    setlisttilte("");
  }, [activeLGa]);
  return (
    <div className="slide-in-right">
      <div className="flex justify-between items-center  ">
        <p className="font-semibold text-sm text-primary my-3 ">
          All LGAs {LGAs.length}
        </p>
        <div className="  rounded place-self-center flex gap-10 text-gray-700   ">
          <IoIosArrowRoundBack
            size={30}
            className=""
            onClick={() => {
              document
                .getElementById("scrollableDiv")
                .scrollBy({ left: -110, behavior: "smooth" });
            }}
          />

          <IoIosArrowRoundForward
            size={30}
            className=" "
            onClick={() => {
              document
                .getElementById("scrollableDiv")
                .scrollBy({ left: 110, behavior: "smooth" });
            }}
          />
        </div>
      </div>
      <div
        id={"scrollableDiv"}
        className=" flex relative w-full pt-10 overflow-x-scroll    bg-gray-200 p-3 rounded-lg "
      >
        <small className="absolute top-3 left-3 text-primary">
          Select any LGA
        </small>
        {LGAs.map((item, index) => (
          <FilterItem item={item} key={index} />
        ))}
      </div>

      <hr />

      <div className="flex flex-col md:flex-row gap-4">
        <div className={`${showlist ? "w-1/2" : "w-full"}`}>
          <ul className="flex list-disc w-full  font-[500] p-3 gap-4  justify-between items-center border border-gray-400 rounded-lg px-6 my-2">
            <li className="text-sm flex flex-col">
              Total number of schools in LGA
              <span className="font-bold text-3xl  text-gray-500 ml-2">
                {lgaschools.length}
              </span>
              <p
                onClick={() => {
                  setshowlist(true);
                  setviewlist(lgaschools);
                  setlisttilte("number of schools in LGA");
                }}
                className="text-xs my-2 text-blue-500 font-[500] cursor-pointer underline "
              >
                View List <PiArrowUpRight className={"inline"} />
              </p>
            </li>
            <li className="text-sm flex flex-col">
              Total number of school evaluated
              <span className="font-bold text-3xl  text-gray-500 ml-2">
                {evaluatedschools.length}
              </span>
              <p
                onClick={() => {
                  setshowlist(true);
                  setviewlist(evaluatedschools);
                  setlisttilte("number of schools evaluated");
                }}
                className="text-xs my-2 text-blue-500 font-[500] cursor-pointer underline "
              >
                View List <PiArrowUpRight className={"inline"} />
              </p>
            </li>
            <li className="text-sm flex flex-col">
              Total number of school unevaluated
              <span className="font-bold text-3xl  text-gray-500 ml-2 ">
                {lgaschools.length - evaluatedschools.length}
              </span>
              <p
                onClick={() => {
                  setshowlist(true);
                  setviewlist(unevaluatedschools);
                  setlisttilte("number of schools un-evaluated");
                }}
                className="text-xs my-2 text-blue-500 font-[500] cursor-pointer underline"
              >
                View List <PiArrowUpRight className={"inline"} />
              </p>
            </li>
          </ul>
          <hr />
          <p className="font-semibold text-sm text-primary my-4 ">
            All LIEs in this LGA ({stafflist.length})
          </p>
          {stafflist.length > 0 ? (
            stafflist.map((ik) => (
              <LIEItem
              setshowlist={setshowlist}
                setviewlist={setviewlist}
                asignedschhols={asignedschhols}
                item={ik}
              />
            ))
          ) : (
            <EmptyList />
          )}
        </div>
        {
          <div
            className={` relative ${
              showlist ? "slide-in-right w-1/2 " : "slide-out-right"
            } h-[60vh] overflow-y-scroll`}
          >
            <p className="text-xl my-2 font-light capitalize text-gray-500">
              {listtilte}
            </p>
            <MdClose
              onClick={() => {
                setshowlist(false);
              }}
              size={30}
              className="absolute top-5 right-5"
            />
            <div>
              {viewlist.length > 0 ? (
                viewlist.map((ll) => <SchoolItem item={ll} setshowmodal={setshowmodal}/>)
              ) : (
                <EmptyList />
              )}
            </div>
          </div>
        }{" "}
      </div>
    </div>
  );
};
export const EmptyList = () => (
  <div className="w-full text-primary bg-gray-200 flex-col rounded-md flex justify-center items-center p-4">
    <PiEmptyLight size={30} className="" />
    <p className="text-xs font-[500] ">No item to show</p>
  </div>
);

const LIEItem = ({ item, asignedschhols, setviewlist , setshowlist}) => {
  const eveluated = asignedschhols.filter((kk) =>
    kk.reviewer
      .toLowerCase()
      .includes(
        `${item.firstname.toLowerCase()} ${item.lastname.toLowerCase()}`
      )
  );
  return (
    <div className=" bg-gray-200 p-3 my-1 flex gap-4 rounded-lg  relative  text-gray-600 justify-between items-center  hover:bg-gray-300 cursor-pointer">
    <div>
      
      
      <img className="w-[50px] h-[50px] rounded-full " src={item.passport} />
      <div>
        <p className="font-semibold">
          {item.firstname} {item.lastname}
        </p>
        <p className="font-[400]">{item.email}</p>
        <p className="font-[400]">{item.phone}</p>
       
      </div>  
      </div> 

      <p
          onClick={() => {
            setshowlist(true)
            setviewlist(eveluated);
          }}
          className="  font-semibold font-serif    my-2 text-blue-500   cursor-pointer underline "
        >
          Evaluations: <span>{eveluated.length}</span>
        </p>
              <StaffActions item={item}/>
      
    </div>
  );
};
const SchoolItem = ({ item, setshowmodal }) => {


  var dispatcher =useDispatch()
  return (
    <div onClick={()=>{

      dispatcher({type:'set-selectedSchool',payload:item})
      setshowmodal(true)

    }} className=" bg-gray-200 p-3 my-1 flex gap-4 rounded-lg  relative  text-gray-600 hover:bg-gray-300 cursor-pointer">
    
     
      <img className="w-[50px] h-[50px] rounded-full " src={item.schoollogo} />
      <div>
        <p className="font-semibold">{item.schoolname}</p>
        <p className="font-[400]">{item.address}</p>
      </div>
    </div>
  );
};
