import StaffActions from "./StaffActions";

 

export default ({ list}) => {
  
 
 
  return (
    <div className="slide-in-right">
      {" "}
     
       
      <hr />
      <p className="font-semibold text-sm text-primary  my-4">
        All HQ (s)   {" "}
         {list.length}
      </p>
      {list.map((ik) => (
        <LIEItem item={ik} />
      ))}
    </div>
  );
};

const LIEItem = ({ item }) => (
  <div className=" bg-gray-200 p-3 my-1 flex rounded-lg  relative  text-gray-600 hover:bg-gray-300   justify-between items-center  cursor-pointer">
       <div>
       <img className="w-[50px] h-[50px] rounded-full " src={item.passport} />
         <div >
         <p className="font-semibold">
      {" "}
      {item.firstname} {item.lastname}{" "}
    </p>
    <p className="font-[400]">{item.email}</p>
    <p className="font-[400]">{item.phone}</p>
      </div>
        
        </div> 

              <StaffActions item={item}/>
  </div>
);
