import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onGetAllSchool } from "../../Utils/Servicess/school";
import Button from "../../Components/generic/Button";
import { FaEdit } from "react-icons/fa";
import { SchoolDeails } from "./SchoolDetails";
import { dummy_image } from "../../constants/imports";
import { onGetAllReview } from "../../Utils/Servicess/review";
import Spinner from "../../Components/generic/Spinner";
import { MdOutlinePending } from "react-icons/md";
import { IoCheckmarkDone } from "react-icons/io5";
import { useForm } from "react-hook-form";
import InputField from "../../Components/generic/Inputfield";
import { emailReg, numReg, textReg } from "../../Utils/regex";

import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { MdDateRange } from "react-icons/md";
import { ListPicker } from "../../Components/ui/ListPicker";
import { usePickerhook } from "../../Hooks/usePickerhook";
import { onUpdateUser } from "../../services/user";

const AccountDashboard = () => {
  var authuser = useSelector((state) => state.userStore);
  var [school, setschool] = useState({});
  const [allschools, setallschools] = useState({});
  const dispatcher = useDispatch();
  const [loading, setLoading] = useState(false);
  const headerItems = ["Dashboard", "Personal info"];
  const [activetab, setactivetab] = useState("Dashboard");
  const [asignedschhols, setasignedschhols] = useState(0);
  const [notasignedschhols, setnotasignedschhols] = useState(0);
  const [showeditmodal, setshoweditmodal] = useState(false);

  const getallschools = async () => {
    var allschools = await onGetAllSchool();
    setschool(allschools[authuser.schhool]);
    setallschools(allschools);
    dispatcher({ type: "set-school", payload: allschools[authuser.schhool] });
    console.log(allschools[authuser.schhool]);
  };

  const getallreviews = async () => {
    var allrevs = await onGetAllReview();
    let notasscount = 0;
    let asscount = 0;
    for (let schollrev in allrevs) {
      let rev = allrevs[schollrev];
      if (rev.status == "nill") {
        notasscount++;
      } else if (rev.status == "asigned") {
        console.log(rev,schollrev);
        asscount++;
      }
    }

     //    setnotasignedschhols(notasscount);
    setasignedschhols(asscount);
  };

  useEffect(() => {
    var init = async () => {


      setLoading(true);

      try{

        await getallschools();
        await getallreviews();
      }

      catch (e) {
      
      }
    finally{
      setLoading(false);


    }
    };

    init();
  }, []);

  const DashboardView = ({ item }) => (
    <div>
      {" "}
      <p className="font-semibold self-start mx-6">Review Status</p>
      <div className=" w-full grid grid-cols-1 md:grid-cols-3 justify-center items-center mt-2 px-6">
        {/* reviewed */}
        <div className=" flex flex-col bg-white border border-primary rounded justify-center items-center p-6 relative   m-2">
          <p className="text-customBlue   m-2 mb-4 text-sm self-start font-semibold">
            Completed / In progress
          </p>
          <IoCheckmarkDone className="inline" />{" "}
          <p className="text-[25px] text-black inline font-bold">
            {asignedschhols}
          </p>
        </div>

        {/* reviewed */}
        <div className=" flex flex-col bg-white border border-primary rounded justify-center items-center p-6 relative   m-2">
          <p className="text-customBlue    m-2 mb-4 text-sm self-start font-semibold">
              Not assigned
          </p>
          <MdOutlinePending className="inline" />{" "}
          <p className="text-[25px] inline text-black font-bold">
            {/* {notasignedschhols} */}
            {(parseInt(Object.keys(allschools).length)-parseInt(asignedschhols))}
          </p>
        </div>
      </div>
    </div>
  );

  const PersonalInfoView = ({ item }) => (
    <div>
      <div className=" w-full   md:flex justify-start items-start p-4  gap-8">
        <img
          className="rounded-full border border-primary  inline"
          height="100px"
          width="100px"
          src={`${authuser.passport}`}
        />
        <div className="w-full font-light">
          <p>
            <span className="font-normal">Firstname: </span>
            {authuser.firstname}
          </p>

          <p>
            <span className="font-normal">Lastname: </span>
            {authuser.lastname}
          </p>
          <p>
            <span className=" font-normal">Email: </span>
            {authuser.email}
          </p>
          <p>
            <span className="font-normal">Date of birth: </span>
            {authuser.dob}
          </p>
          <p>
            <span className="font-normal">Date of entry to service: </span>
            {authuser.doe}
          </p>
          <p>
            <span className="font-normal">Position: </span>
            {authuser.position}
          </p>
          {/* <p>
      <span className="font-normal">Religion: </span>
      {authuser.religion}
    </p> */}
        </div>
        <img
          className="rounded-full border border-primary  inline"
          height="100px"
          width="100px"
          src={`${authuser.iddoc ? authuser.iddoc : dummy_image}`}
        />
      </div>
      <div className="w-full flex justify-end">
        <button
          onClick={() => setshoweditmodal(true)}
          className=" px-3 py-1 m-2  border font-light  border-gray-200 text-sm border-1 rounded text-white  "
        >
          Edit Profile Info
        </button>
      </div>
    </div>
  );

  return (
    <div className=" flex flex-col bg-white justify-center px-4 w-full items-center ">
      <Spinner loading={loading} />

      {/* <p className="font-medium text-customBlue self-start m-4">
        Profile details
      </p> */}

      {showeditmodal && (
        <EditProfileModal det={authuser} show={setshoweditmodal} />
      )}
      <div className="p-2  border-gray-300 mt-2 self-start  border flex gap-4 rounded ">
        {headerItems.map((ik) => (
          <div
            onClick={() => {
              setactivetab(ik);
            }}
            className={` text-gray-500 cursor-pointer px-2 py-1 rounded ${
              activetab == ik
                ? "bg-primary text-white text-sm font-semibold "
                : ""
            }`}
          >
            {ik}
          </div>
        ))}
      </div>

      <hr />

      <div className=" rounded text-white  bg-customBlack py-4  my-4 w-full mx-4 ">
        {activetab == "Dashboard" && <DashboardView />}

        {activetab == "Personal info" && <PersonalInfoView />}
      </div>
    </div>
  );
};

export { AccountDashboard };

const EditProfileModal = (props) => {
  const [loading, setloading] = useState(false);
  const { control, handleSubmit } = useForm();
  var authuser = useSelector((state) => state.userStore);

  const dispather = useDispatch();
  const positionhook = usePickerhook();

  const [DOB, setDOB] = useState(new Date());
  const [DOE, setDOE] = useState(new Date());
  const positions = [
    "Permanent Secretary (1)",
    "Director of QA (1)",
    "Deputy Directors of QA (2)",
    "Assistant Director (1)",
    "Chief Education Officer (1)",
    "Assistant Chief Education Officer (2)",
    "Principal Education Officer (2)",
    "Senior Education Officer (1)",
  ];
  const onSubmit = async (data) => {
    var postdata = {
      ...data,
      position: positionhook.pickedRole
        ? positionhook.pickedRole
        : props.det.position,
      doe: DOE.toDateString(),

      dob: DOB.toDateString(),
    };

    try {
      setloading(true);
      const res = await onUpdateUser({ data: { ...postdata } });
      toast.success("Profile updated!");

      localStorage.clear();
      var user = { ...authuser, ...postdata };
      dispather({ type: "set-user", payload: user });
      localStorage.setItem(`GovstaffUserData`, JSON.stringify(user));
      props.show(false);
    } catch (e) {
      console.log(e);
      toast.error(e);
    } finally {
      setloading(false);
    }
  };

  return (
    <div className=" bg-[#00000091] w-screen h-screen fixed flex justify-center items-center z-[300001] top-0 left-0">
      <Spinner loading={loading} />

      <div className="bg-white rounded p-4   min-w-[30%] md:max-w-[40%] max-h-[500px] overflow-y-scroll ">
        <p className="text-primary font-semibold">Edit Profile info</p>
        <InputField
          name="firstname"
          title="Firstname"
          def_value={props.det.firstname}
          control={control}
          rules={{
            required: "Firstname is required",
            pattern: {
              value: textReg,
              message: "Invalid Firstname",
            },
          }}
        />

        <InputField
          name="lastname"
          title="lastname"
          def_value={props.det.lastname}
          control={control}
          rules={{
            required: "lastname is required",
            pattern: {
              value: textReg,
              message: "Invalid lastname",
            },
          }}
        />
        <InputField
          name="email"
          title="Email"
          def_value={props.det.email}
          isDisabled={true}
          control={control}
          rules={{
            required: "Email is required",
            pattern: {
              value: emailReg,
              message: "Invalid Email",
            },
          }}
        />

        {/* Date of birth */}
        <div className="my-4">
          <p className="text-xs mb-2 ">Date Of Birth</p>
          <div className=" px-4 py-1  flex text-sm bg-gray-50 w-full  rounded   items-center">
            <MdDateRange className="p-2 " size={30} />

            <DatePicker
              className="font-light"
              selected={DOB}
              onChange={(date) => setDOB(date)}
            />
          </div>
        </div>
        {/* Date of entry to service */}
        <div className="my-4">
          <p className="text-xs mb-2 ">Date of entry to service</p>
          <div className=" px-4 py-1  flex text-sm bg-gray-50 w-full  rounded   items-center">
            <MdDateRange className="p-2 " size={30} />

            <DatePicker
              className="font-light"
              selected={DOE}
              onChange={(date) => setDOE(date)}
            />
          </div>
        </div>

        <ListPicker title="Position" hook={positionhook} list={positions} />

        <div className="w-full  flex  gap-3 justify-end">
          <Button
            width={40}
            outlined
            text="Cancel"
            onBtnClick={() => {
              props.show(false);
            }}
          />
          <Button width={55} text="Save" onBtnClick={handleSubmit(onSubmit)} />
        </div>
      </div>
    </div>
  );
};
