import { useEffect, useState } from "react";
import Button from "../generic/Button";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Spinner from "../generic/Spinner";
import { MdClose } from "react-icons/md";
import { removeNonLetters } from "../../Utils/govfunctions";

const ReviewQuestModal = (props) => {

  useEffect(()=>{


  },[])
  return (
    <div
      onClick={() => props.show(false)}
      className=" bg-[#0000004f] w-screen h-screen fixed flex justify-center items-center top-0 left-0"
    >
      <div className="bg-white rounded p-8 w-[40%] flex h-[90vh]   overflow-y-scroll overflow-x-hidden items-center  flex-col">
        <MdClose
          onClick={() => {
            props.show(false);
            return;
          }}
          className={"text-primary self-end bg-gray-100 rounded-full"}
          size={40}
        />
        <p className="font-bold text-primary">Review and rating basis</p>{" "}
        <p className="text-xs">
          Note: Only assigned evaluator can edit review school
        </p>
        {props.questions.map((item) => (
          <div className="w-full m-3">
            <p className="font-normal ">
              {item.title} <br />{" "}
              {` Rated: ${
                props.rated
                  ? props.rated[removeNonLetters(item.title)]
                  : "not rated yet"
              }`}
            </p>
            <p className="text-primary text-sm">
              {" "}
              {item.type == "single"
                ? `Grade:${item.score} `
                : item.questions.map((item) => (
                    <div className="w-full m-3">
                      <p className="font-normal ">{item.title} </p>
                      <p className="text-primary text-sm">
                        {" "}
                        {`Grade:${item.score} `}
                      </p>
                    </div>
                  ))}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};
export { ReviewQuestModal };
