import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { onGetAllfacility } from "../../services/facility";
import { FaTrash } from "react-icons/fa";
import { PiEmptyThin } from "react-icons/pi";
import { onGetAllgovstaffs, onGetAllMessages } from "../../services/user";
import { toast } from "react-toastify";
import MessageItem from "./message/MessageItem";
import Spinner from "../../Components/generic/Spinner";
import { removeNonLetters } from "../../Utils/govfunctions";
import { FaArrowLeft } from "react-icons/fa6";
import { CiInboxIn, CiInboxOut } from "react-icons/ci";
import ComposeMsgRe from "./message/ComposeMsgRe";
import { IoIosRefresh } from "react-icons/io";
import Button from "../../Components/generic/Button";
import LIEsListing from "../../Components/EvaluationsTab/LIEsListing";
import ZEListing from "../../Components/EvaluationsTab/ZEListing";
import { onGetAllSchool } from "../../services/school";
import { onGetAllReview } from "../../Utils/Servicess/review";
import HQListing from "../../Components/EvaluationsTab/HQListing";
import { ReviewStatusModal } from "../../Components/Review/ReviewStatusModal";

export default () => {
  const dispatcher = useDispatch();
  const [loading, setLoading] = useState(false);
  const [Checked, setchecked] = useState([]);
  const [allschools, setallschools] = useState([]);

  const school_ = useSelector((state) => state.selectedSchool);
  var authuser = useSelector((state) => state.userStore);
  const [asignedschhols, setasignedschhols] = useState([]);
  const [notasignedschhols, setnotasignedschhols] = useState([]);
  const [active, setactive] = useState("LIEs");
  const [staffs, setstaff] = useState([]);
  var [showmodal, setshowmodal] = useState(false);

  const FilterItem = ({ item, click }) => (
    <div
      onClick={() => {
        setactive(item);
        click();
      }}
      className={`${
        active == item
          ? "bg-primary font-semibold flex justify-center items-center text-white"
          : "  border border-gray-300 "
      } cursor-pointer rounded min-w-[200px] flex justify-center items-center  font-semibold text-sm p-2  mx-3`}
    >
      {item}
    </div>
  );

  const getallschools = async () => {
    let emptarr = [];
    var allschools = await onGetAllSchool();

    for (let school in allschools) {
      emptarr.push(allschools[school]);
    }
    setallschools(emptarr);
  };

  const getallreviews = async () => {
    setLoading(true);
    try {
      var allrevs = await onGetAllReview();
      let notasscount = [];
      let asscount = [];
      for (let schollrev in allrevs) {
        let rev = allrevs[schollrev];
        if (rev.status == "nill") {
          notasscount.push({
            ...rev,
            schoolname: schollrev,
            address: allschools.filter(
              (kl) => kl.schoolname?.toLowerCase() == schollrev?.toLowerCase()
            )[0]?.address,
          });
        } else if (rev.status == "asigned") {
          asscount.push({
            ...rev,
            schoolname: schollrev,
            address: allschools.filter(
              (kl) => kl.schoolname?.toLowerCase() == schollrev?.toLowerCase()
            )[0]?.address,
          });
        }
      }

      setnotasignedschhols(notasscount);
      setasignedschhols(asscount);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    var init = async () => {
      setLoading(true);

      try {
        await getallschools();
      } catch (e) {
      } finally {
        setLoading(false);
      }
    };

    init();
  }, []);

  useEffect(() => {
    getallreviews();
  }, [allschools]);
  const getallStaffs = async () => {
    let emptarr = [];
    setLoading(true);

    try {
      var res = await onGetAllgovstaffs();
      for (let staff in res) {
        emptarr.push(res[staff]);
      }
      setstaff(emptarr);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getallStaffs();
  }, []);

  dispatcher({ type: "set-pagetitle", payload: "Evalutaion" });

  useEffect(() => {
    console.log(Checked);
  }, [Checked]);
  return (
    <div>
      {showmodal && (
        <ReviewStatusModal isEvaluation={true} setrevmodal={setshowmodal} />
      )}

      <Spinner loading={loading} />
      <div className="border flex border-gray-400  p-2 mx-10 items-center justify-between md: min-w-[50%]">
        <FilterItem click={() => {}} item="LIEs" />
        <FilterItem click={() => {}} item="ZIs" />
        <FilterItem click={() => {}} item="HQ officials" />
        <IoIosRefresh
          onClick={async () => {}}
          size={20}
          className="inline text-primary"
        />
      </div>
      <div className="p-8">
        {active == "LIEs" && (
          <LIEsListing
            setshowmodal={setshowmodal}
            allschools={allschools}
            asignedschhols={asignedschhols}
            notasignedschhols={notasignedschhols}
            list={staffs.filter(
              (km) => km.inspectortype == "LocalInspectorLIE"
            )}
          />
        )}
        {active == "ZIs" && (
          <ZEListing
            allschools={allschools}
            asignedschhols={asignedschhols}
            notasignedschhols={notasignedschhols}
            list={staffs.filter((km) => km.inspectortype == "ZonalInspectorZI")}
          />
        )}
        {active == "HQ officials" && (
          <HQListing
            list={staffs.filter((km) => km.inspectortype == "HQOfficers")}
          />
        )}
      </div>
    </div>
  );
};
